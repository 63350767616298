import fakeimagen from "images/fakeimagen.jpg";

export const fakeList = [
    {
        nombre: "canrgando",
        imagen: fakeimagen,
    },
    {
        nombre: "cargando",
        imagen: fakeimagen,
    },
    {
        nombre: "cargando",
        imagen: fakeimagen,
    },
    {
        nombre: "cargando",
        imagen: fakeimagen,
    },
    {
        nombre: "cargando",
        imagen: fakeimagen,
    },
    {
        nombre: "cargando",
        imagen: fakeimagen,
    },
];